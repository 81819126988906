<script setup>
import HeaderView from "./views/HeaderView.vue";
import BannerView from "./views/BannerView.vue";
import CharacteristicsView from "./views/CharacteristicsView.vue";
import PropertiesView from "./views/PropertiesView.vue";
import DescriptionView from "./views/DescriptionView.vue";
import ComposionsView from "./views/ComposionsView.vue";
import FooterView from "./views/FooterView.vue";
import AdvantagesView from "./views/AdvantagesView.vue";
import { onMounted } from "vue";
import AOS from "aos";

onMounted(() => {
  AOS.init({
    disable: "mobile",
    duration: 500,
  });
});
</script>

<template>
  <header-view />
  <banner-view />
  <description-view />
  <characteristics-view />
  <properties-view />
  <composions-view />
  <advantages-view />
  <div class="fsi container">
    <h1 data-aos="fade-right">Поддержано Фондом содействия инновациям</h1>
    <img
      data-aos="fade-left"
      data-aos-delay="300"
      src="@/assets/fsi.png"
      alt="fsi_logo"
    />
  </div>
  <footer-view />
</template>

<style lang="scss">
// Reset styles
html {
  box-sizing: border-box;
  font-size: 16px;

  @media (max-width: 45rem) {
    font-size: 14px;
  }

  @media (max-width: 30rem) {
    font-size: 12px;
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

h1,
h2 {
  font-family: "Roboto Slab";
  font-size: 2rem;
  line-height: 2.5rem;
  letter-spacing: 0.125rem;
  font-weight: 700;
}

p {
  font-family: "Roboto Slab";
  font-size: 1rem;
  line-height: 1.25rem;
}

.container {
  max-width: 90rem;
  width: 100%;
  margin: 0 auto;
  padding: 3rem;

  @media (max-width: 40rem) {
    padding: 1.5rem;
  }
}

#description,
#characteristics,
#composions,
#advantages {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2.5rem;
  overflow: hidden;
}

.fsi {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding-top: 0;

  @media (max-width: 40rem) {
    flex-direction: column;
    text-align: center;
  }

  h1 {
    max-width: 30rem;
  }

  img {
    max-width: min(14rem, 100%);
  }
}
</style>
