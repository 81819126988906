<template>
  <header ref="header" class="header">
    <div class="container header__container">
      <router-link
        class="logo-wrapper"
        @click="isShown = false"
        :to="{ hash: '' }"
      >
        <img class="logo" src="@/assets/logo.png" alt="logo" />
        <div class="logo-text">
          <span>КЛАСТЕК</span>
          <span>ПАК «След»</span>
          <span>+7 (499) 460-68-30</span>
        </div>
      </router-link>

      <div class="header__menu" :class="menuClass">
        <nav class="header__nav">
          <ul>
            <router-link
              @click="isShown = false"
              :to="{ hash: '#description' }"
            >
              <li class="header__nav-item">Описание</li>
            </router-link>
            <router-link
              @click="isShown = false"
              :to="{ hash: '#characteristics' }"
            >
              <li class="header__nav-item">Характеристики</li>
            </router-link>
            <router-link @click="isShown = false" :to="{ hash: '#properties' }">
              <li class="header__nav-item">Особенности</li>
            </router-link>
            <router-link @click="isShown = false" :to="{ hash: '#composions' }">
              <li class="header__nav-item">Состав продукта</li>
            </router-link>
            <router-link @click="isShown = false" :to="{ hash: '#advantages' }">
              <li class="header__nav-item">Наши достоинства</li>
            </router-link>
            <a
              @click="isShown = false"
              class="header__nav-link"
              href="/order.html"
            >
              <li class="header__nav-item">Заказать</li>
            </a>
          </ul>
        </nav>
      </div>

      <Hamburger v-model="isShown"></Hamburger>
    </div>
  </header>
</template>

<script setup>
import { computed, ref } from "vue";

import Hamburger from "@/components/Hamburger.vue";

const isShown = ref(false);

const menuClass = computed(() => (isShown.value ? "header__menu--open" : ""));
</script>

<style lang="scss">
.header {
  position: sticky;
  z-index: 2;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 0 20px 30px 0 rgba(28, 9, 80, 0.05);

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 16px 32px !important;
  }

  &__menu {
    display: flex;
    align-items: center;
    gap: 50px;

    @media (max-width: (67.375rem)) {
      & {
        position: absolute;
        flex-direction: column-reverse;
        justify-content: center;
        min-height: calc(100vh - 6rem);
        width: 100%;
        top: 6rem;
        left: 0;
        background-color: #fff;
        margin-left: -100%;
        transition: margin-left 0.3s;
        padding: 20px 0;
        gap: 20px;
      }

      &--open {
        margin-left: 0;
      }
    }
  }

  &__nav {
    @media (max-width: (67.375rem)) {
      & {
        width: 100%;
      }
    }

    & ul {
      list-style: none;
      display: flex;
      align-items: center;
      gap: 1rem;

      & a {
        font-size: 1rem;
        line-height: 1rem;
        font-weight: 700;
        font-family: "Open Sans";
        color: inherit;
        text-decoration: none;
      }

      @media (max-width: (67.375rem)) {
        & {
          width: 100%;
          flex-direction: column;
        }

        @media (max-height: 404px) {
          & {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
          }
        }
      }
    }

    &-item {
      position: relative;
      color: #000;
      text-align: center;
      cursor: pointer;

      @media (max-width: (67.375rem)) {
        & {
          font-size: 22px;
          padding: 5px 0;
        }
      }
    }

    &-link {
      padding: 0.5rem 1.5rem;
      border-radius: 0.5rem;
      text-decoration: none;
      background-color: rgba($color: #f2a516, $alpha: 0.7);
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 15px;
  }
}

.logo {
  width: 64px;
  height: 64px;
}

.logo-wrapper {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  text-decoration: none;
  color: #000;

  span {
    font-size: 1.25rem;
    line-height: 1.25rem;
    font-weight: 700;
    font-family: "Open Sans";

    &:last-child {
      font-size: 0.75rem;
      line-height: 1.5rem;
    }
  }
}

.logo-text {
  display: flex;
  flex-direction: column;
}
</style>
