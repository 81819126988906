<script setup>
import CustomTitle from "@/components/CustomTitle.vue";

const PROPERTIES = [
  "Использование нескольких каналов входящих данных — видео, видео высокого разрешения, данные радиолокатора GSM диапазона",
  "Применение комплекса алгоритмов ИИ",
  "Возможность отслеживать медленно и быстро движущиеся объекты",
  "Обнаружение изменений на местности",
  "Работа в суровых погодных условиях",
  "Возможность размещения на БПЛА самолётного типа и мульти роторных БПЛА",
];
</script>

<template>
  <div id="properties" class="container">
    <custom-title>Особенности</custom-title>
    <div data-aos="zoom-out" data-aos-delay="300" class="card">
      <h1>Особенности ПАК «След»</h1>
      <ul>
        <li v-for="property in PROPERTIES" :key="property">
          <div class="card-icon"></div>
          <p>{{ property }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
h1 {
  text-align: center;
}

.card {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1.5rem 2rem 2rem 2rem;
  margin: 2.5rem auto 0 auto;
  border-radius: 0.5rem;
  background-color: rgba($color: #f2a516, $alpha: 0.7);
  color: #000;
  max-width: 36rem;
  width: 100%;

  ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
    width: 100%;

    li {
      display: flex;
      gap: 0.75rem;

      p {
        margin-top: 0.125rem;
      }
    }
  }

  &-icon {
    flex-shrink: 0;
    width: 1.5rem;
    height: 1.5rem;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M8.79502 15.875L4.62502 11.705L3.20502 13.115L8.79502 18.705L20.795 6.70504L19.385 5.29504L8.79502 15.875Z' fill='%23bf4545' /%3E%3C/svg%3E");
  }
}
</style>
