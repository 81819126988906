import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "banner",
    component: () => import("@/views/BannerView.vue"),
  },
  {
    name: "description",
    path: "/description",
    component: () => import("@/views/DescriptionView.vue"),
  },
  {
    name: "characteristics",
    path: "/characteristics",
    component: () => import("@/views/CharacteristicsView.vue"),
  },
  {
    name: "properties",
    path: "/properties",
    component: () => import("@/views/PropertiesView.vue"),
  },
  {
    name: "composion",
    path: "/composion",
    component: () => import("@/views/ComposionsView.vue"),
  },
  {
    name: "advantages",
    path: "/advantages",
    component: () => import("@/views/AdvantagesView.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        el: to.hash,
        top: 96,
        behavior: "smooth",
      };
    } else {
      return {
        top: 0,
        behavior: "smooth",
      };
    }
  },
});

export default router;
