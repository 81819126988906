<template>
  <div class="title">
    <h2 data-aos="zoom-in">
      <slot />
    </h2>
    <div data-aos="zoom-in" data-aos-delay="300" class="title__line"></div>
  </div>
</template>

<style scoped lang="scss">
.title {
  width: 100%;
  text-align: center;

  h2 {
    color: #f28322;
    margin-bottom: 0.25rem;
  }

  &__line {
    background-color: #f23838;
    max-width: min(32rem, 80vw);
    margin: 0 auto;
    width: 100%;
    height: 0.25rem;
    border-radius: 0.25rem;
  }
}
</style>
