<script setup>
import CustomTitle from "@/components/CustomTitle.vue";

const TABLE_ROWS = [
  {
    key: "Частота кадров *",
    value: "От 1 в секунду",
  },
  {
    key: "Разрешение",
    value: "От HD до 4K",
  },
  {
    key: "Точность обнаружения класса объекта",
    value: "От 90%",
  },
  {
    key: "Максимальная удалённость дрона **",
    value: "До 20 км",
  },
  {
    key: "Количество подключаемых камер",
    value: "До 4-х",
  },
  {
    key: "Считывание изображений с видеокамер",
    value: "VIS, IR спектров",
  },
  {
    key: "Инференция нейросетей различных архитектур на различных ускорителях ИНС",
    value: "CPU, CUDA, FPGA, TPU и других",
  },
  {
    key: "Отправка изображений с видеокамер, результатов их инференции и данных с датчиков, актуальных для данных изображений по сети интернет",
    value: "Да",
  },
  {
    key: "Поддержка настраиваемых режимов отправки изображений при наличии объектов поиска или их отсутствии",
    value: "Да",
  },
  {
    key: "Хранение в базе данных изображений с видеокамер, результатов их инференции и данных с датчиков, актуальных для данных изображений",
    value: "Да",
  },
  {
    key: "Поставка инструментов для конфигурации и работы с базой данных по сети интернет",
    value: "Да",
  },
  {
    key: "Скорость распознавания",
    value: "10 кадров в секунду",
  },
  {
    key: "Точность алгоритма распознавания и классификации (метрика достоверности)",
    value: "не менее 85%",
  },
];
</script>

<template>
  <div id="characteristics" class="container">
    <custom-title>Характеристики</custom-title>
    <div class="table">
      <div
        v-for="(row, index) in TABLE_ROWS"
        :key="index"
        :data-aos="index % 2 === 0 ? 'fade-right' : 'fade-left'"
        :data-aos-delay="index * 50"
        class="table__item"
      >
        <p>{{ row.key }}</p>
        <p>{{ row.value }}</p>
      </div>
      <p class="table__additional">
        * Значение приведено при обработке кадра разрешением 4К. В случае
        меньшего разрешения частота кадров регулируется на программном уровне с
        рабочего места оператора.
      </p>
      <p class="table__additional">
        ** Значение зависит от используемого типа беспилотного летательного
        аппарата и его характеристик.
      </p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.table {
  max-width: 50rem;

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1.25rem;
    column-gap: 5rem;
    row-gap: 0.5rem;

    &:nth-child(odd) {
      background-color: rgba($color: #bf4545, $alpha: 0.25);
    }

    @media (max-width: 25rem) {
      flex-direction: column;
      align-items: baseline;

      & > p:first-child {
        color: #f28322;
      }
    }

    p:last-child {
      text-align: right;
    }
  }

  &__additional {
    font-size: 0.75rem;
    line-height: 1rem;

    &:first-of-type {
      margin: 1.5rem 0 0.75rem 0;
    }
  }
}
</style>
