<template>
  <footer>
    <div class="footer-inner">
      <div class="footer-inner__line"></div>
      <p>© 2018-2023 ООО «Кластек»</p>
      <a href="mailto:info@clustech.ru">info@clustech.ru</a>
      <a href="tel:+74994606830">+7 (499) 460-68-30</a>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
.footer-inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 90rem;
  width: 100%;
  margin: 0 auto;
  padding: 1.75rem 2rem 1.5rem 2rem;
  gap: 0.5rem;

  @media (max-width: 30rem) {
    flex-direction: column;
  }

  a {
    font-family: "Roboto Slab";
    font-size: 1rem;
    line-height: 1.25rem;
    color: inherit;
    text-decoration: none;
  }

  &__line {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 0.25rem;
    background-color: #ccc;
    border-radius: 0.25rem;
  }
}
</style>
