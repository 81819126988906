<template>
  <div id="banner">
    <h1 data-aos="fade-down">Программно-аппаратный комплекс «След»</h1>
    <p data-aos="fade-down" data-aos-delay="300">
      Система автоматизированного поиска людей на больших территориях с бортовым
      анализом данных
    </p>
  </div>
</template>

<style scoped lang="scss">
#banner {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  max-width: 45.5rem;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 9rem 1.5rem 6rem 1.5rem;
}
</style>
